<script setup>
import Button from "~/components/atoms/Button.vue";
import Recaptcha from "~/components/molecules/Recaptcha.vue";

const props = defineProps({
  method: String,
  action: String,
  loading: Boolean,
  staticWidth: Boolean,
  errors: Array,
  submitPadding: String
});

const {method, action} = props;

const validators = ref([]);

const registerValidator = (validator) => {
  validators.value.push(validator);
};

const unregisterValidator = (validator) => {
  validators.value = validators.value.filter(v => v !== validator);
};

provide('registerValidator', registerValidator);
provide('unregisterValidator', unregisterValidator);

const emit = defineEmits(['submit']);
const form = ref(null);

const onSubmit = () => {
  const validationResults = validators.value.map(validator => validator());
  if (validationResults.every(result => result)) {
    const formData = new FormData(form.value)
    formData.append('g-recaptcha-response', recaptchaToken.value);
    emit('submit', formData);
  }
}

const siteKey = '6Ld-ILYUAAAAANSR8pLQVZNedFGYc2TYg6XIG-BB';
const recaptchaToken = ref('');
const recaptcha = ref(null);

const handleRecaptcha = () => {
  recaptcha.value.executeRecaptcha();
};

const onRecaptchaVerified = (token) => {
  recaptchaToken.value = token;
  onSubmit();
};

</script>

<template>
  <form ref="form" @submit="onSubmit" :class="{'static-width':staticWidth}" class="g-16 the-same-gap" :action="action"
        :method="method" style="position: relative;">
    <slot></slot>
    <Recaptcha style="display: none" ref="recaptcha" :siteKey="siteKey" :callback="onRecaptchaVerified"/>
    <div :style="{padding: props.submitPadding}">
      <Button @click.stop="handleRecaptcha" :loading="loading" class="full-width" type="black tall uppercase"
              v-if="$slots.submit">
        <slot name="submit"/>
      </Button>
    </div>

    <ul v-if="props.errors?.length" class="errors">
      <li v-for="error in props.errors" :key="error">{{ error }}</li>
    </ul>
  </form>
</template>

<style scoped lang="scss">
form {
  display: flex;
  flex-direction: column;
}

.errors {
  padding: 0;
  list-style: none;
  color: $red;
}

.static-width {
  width: 468px;
  max-width: 100%;
}

button {
  font-size: 14px!important;
}
</style>
